import { useMeasure } from 'react-use';

import { getIconProps } from '@utils';
import Icon from '@elements/Icon';
import buttonCn from '@utils/buttonCn';

const LoadingState = () => (
  <div role="status">
    <Icon
      iconName="spinner-third"
      className="animate-spin"
    />
    <span className="sr-only">
      Loading...
    </span>
  </div>
);

const ButtonIcon = (props) => {
  const {
    isVisible,
    ...iconProps
  } = props;

  const styles = {
    visibility: isVisible ? 'visible' : 'hidden',
  };

  if (!iconProps) return null;

  return (
    <span className="inline-flex size-4 shrink-0 items-center justify-center" style={styles}>
      <Icon
        {...iconProps}
      />
    </span>
  );
};

const ButtonText = (props) => {
  const {
    value,
    isVisible,
  } = props;

  const styles = {
    visibility: isVisible ? 'visible' : 'hidden',
  };

  if (!value) return null;

  return (
    <span style={styles}>
      {value}
    </span>
  );
};

const defaultProps = {
  className: '',
  value: 'Click',
  type: 'button',
  ariaLabel: 'button',

  isDisabled: false,
  isVisible: true,
  isLoading: false,

  iconSize: 'sm',
  onClick: (event) => (event.preventDefault()),
};

export default function Button(props) {
  const {
    id,
    className,
    value,
    type,
    tooltip,
    ariaLabel,
    color,

    iconName,
    iconProps,
    iconSize,
    rightIconName,
    fallbackIconName,

    disableWith,
    minWidth,
  } = { ...defaultProps, ...props };

  const isDisabled = props.isDisabled ?? defaultProps.isDisabled;
  const isVisible = props.isVisible ?? defaultProps.isVisible;
  const isLoading = props.isLoading ?? defaultProps.isLoading;

  const onClick = props.onClick ?? defaultProps.onClick;

  const isIconOnly = props.isIconOnly ?? defaultProps.isIconOnly;

  /**************************************************************************************************/

  const leftIconProps = getIconProps(iconName, {
    fixedWidth: true,
    size: iconSize,
    ...iconProps,
  });

  const rightIconProps = getIconProps(rightIconName, {
    fixedWidth: true,
    size: iconSize,
  });

  const fallbackIconProps = getIconProps(fallbackIconName, {
    fixedWidth: true,
    size: iconSize,
  });

  /***************************************************************************************************
  ** TODO: WIP
  ***************************************************************************************************/

  const [ref, { width }] = useMeasure();

  const displayFallbackIcon = Boolean(fallbackIconName) && Boolean(minWidth) && (width < minWidth);

  /**************************************************************************************************/

  const classNames = buttonCn({ color: color, className: className });

  if (!isVisible) return null;

  return (
    <button
      id={id}
      ref={ref}
      className={classNames}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
      data-disable-with={disableWith}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
    >
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {iconName && (
            <ButtonIcon
              isVisible={!isLoading}
              {...leftIconProps}
            />
          )}

          {!isIconOnly && !displayFallbackIcon && (
            <ButtonText
              value={value}
              isVisible={!isLoading}
            />
          )}

          {!isIconOnly && displayFallbackIcon && (
            <ButtonIcon {...fallbackIconProps} />
          )}

          {rightIconName && (
            <ButtonIcon
              isVisible={!isLoading}
              {...rightIconProps}
            />
          )}
        </>
      )}
    </button>
  );
}
