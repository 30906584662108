import { useOperation } from '@hooks';
import NavbarMenuBaseItem from '@components/navbar/menu/menu_item_types/NavbarMenuBaseItem';

export default function NavbarMenuOperationItem(props) {
  const {
    label,
    operation,
  } = props;

  const {
    text,
  } = label;

  /**************************************************************************************************/

  const enqueueOperation = useOperation({
    key: operation.key,
    args: operation.args,
    callback: operation.callback,
    enqueueDelay: operation.enqueueDelay,
  });

  const handleClick = (_event) => {
    if (window.confirm(`Are you sure you want to execute: ${text}?`)) {
      enqueueOperation();
    }
  };

  /**************************************************************************************************/

  return (
    <NavbarMenuBaseItem
      onClick={handleClick}
      {...props}
    />
  );
}
