import { useState, useRef, useMemo } from 'react';
import { ControlledMenu, useClick } from '@szhsin/react-menu';

import cn from '@utils/cn';
import NavbarMenuItem from '@components/navbar/menu/NavbarMenuItem';
import NavbarMenuButton from '@components/navbar/menu/NavbarMenuButton';

const MENU_CLASS_NAMES = cn(`
  box-border
  z-50
  text-sm
  bg-white
  py-1.5
  border
  border-gray-300
  rounded-lg
  shadow-lg
  shadow-black/10
  select-none
  min-w-60
  focus:outline-hidden
`);

export default function NavbarMenuSlot(props) {
  const {
    label,
    items,
    isAlignedRight,
    menuClassName,
  } = props;

  const [isOpen, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const anchorProps = useClick(isOpen, setOpen);

  const visibleItems = useMemo(() => items?.filter((item) => {
    if ((item.isHidden) || (item.isAdminOnly && !window.appContext.isCurrentUserAdmin)) return false;
    if (item.isVisible !== undefined && item.isVisible !== 'true') return false;

    return true;
  }), [items]);

  /**************************************************************************************************/

  const menuClassNames = cn(MENU_CLASS_NAMES, menuClassName, {
    // 'animate-fade-in-down': isOpen,
    // 'animate-fade-out-up': !isOpen,
  });

  return (
    <div>
      <NavbarMenuButton
        label={label}

        anchorRef={anchorRef}
        anchorProps={anchorProps}
        isOpen={isOpen}
      />
      <ControlledMenu
        anchorRef={anchorRef}
        state={isOpen ? 'open' : 'closed'}
        transition={true}
        align={isAlignedRight ? 'end' : 'start'}
        position="anchor"
        direction="bottom"
        gap={2}
        onClose={() => setOpen(false)}
        menuClassName={menuClassNames}
      >
        {visibleItems.map((item, index) => (
          <NavbarMenuItem
            key={index}
            {...item}
          />
        ))}
      </ControlledMenu>
    </div>
  );
}
