// @index('./slot_types/(?!index|_)*.js', f => `import ${f.name} from '@components/navbar/slot_types/${f.name}';`)
import NavbarLinkSlot from '@components/navbar/slot_types/NavbarLinkSlot';
import NavbarMenuSlot from '@components/navbar/slot_types/NavbarMenuSlot';
// @endindex

const SLOT_TYPE_COMPONENTS = {
  link: NavbarLinkSlot,
  menu: NavbarMenuSlot,
};

export default function NavbarSlot(props) {
  const {
    type,
  } = props;

  const SlotComponent = SLOT_TYPE_COMPONENTS[type];
  if (!SlotComponent) return null;

  return (
    <SlotComponent {...props} />
  );
}
