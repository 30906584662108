/***************************************************************************************************
** buttonCn
***************************************************************************************************/

// 🔵 TODO: Field Theme

import cn from '@utils/cn';

const CLASS_NAMES = cn(`
  peer
  flex
  w-full
  rounded-md
  px-4
  py-2.5

  transition-all
  duration-500
  ease-in-out

  text-base
  font-normal
  border
  ring-0
  outline-0

  text-gallery-700
  bg-white
  border-gallery-300
  ring-steel-600/30
  outline-transparent
  placeholder-gallery-400

  focus:ring-2
  focus:border-steel-600

  group-hover:border-gallery-400
  group-hover:focus:border-steel-600
  group-hover:active:border-steel-600

  group-data-[disabled=true]:text-[#737373]
  group-data-[disabled=true]:bg-[#F5F5F5]
  group-data-[disabled=true]:border-[#D4D4D4]
  group-data-[disabled=true]:cursor-not-allowed

  group-data-[changed=true]:text-[#171717]
  group-data-[changed=true]:bg-[#FFFFE1]
  group-data-[changed=true]:border-[#FFC000]
  group-data-[changed=true]:ring-[#FFC000]/30
  group-data-[changed=true]:focus:text-[#171717]
  group-data-[changed=true]:focus:bg-[#FFFFE1]
  group-data-[changed=true]:focus:border-[#FFC000]
  group-data-[changed=true]:focus:ring-[#FFC000]/30
  group-data-[changed=true]:active:text-[#171717]
  group-data-[changed=true]:active:bg-[#FFFFE1]
  group-data-[changed=true]:active:border-[#FFC000]
  group-data-[changed=true]:active:ring-[#FFC000]/30

  group-data-[invalid=true]:text-[#9E0000]
  group-data-[invalid=true]:bg-[#FFE1E1]
  group-data-[invalid=true]:border-[#9E0000]
  group-data-[invalid=true]:ring-[#9E0000]/30
  group-data-[invalid=true]:focus:text-[#9E0000]
  group-data-[invalid=true]:focus:bg-[#FFE1E1]
  group-data-[invalid=true]:focus:border-[#9E0000]
  group-data-[invalid=true]:focus:ring-[#9E0000]/30
  group-data-[invalid=true]:active:text-[#9E0000]
  group-data-[invalid=true]:active:bg-[#FFE1E1]
  group-data-[invalid=true]:active:border-[#9E0000]
  group-data-[invalid=true]:active:ring-[#9E0000]/30
`);

export default function fieldCn(...classNames) {
  return cn(CLASS_NAMES, classNames);
}
