/***************************************************************************************************
** buttonCn
***************************************************************************************************/

import cn from '@utils/cn';

const BASE_CN = cn(`
  h-10
  transition-all
  rounded-md
  px-4
  py-2
  text-center
  text-base
  font-medium
  select-none
  border
  outline-hidden
  focus:outline-hidden
  text-nowrap

  flex
  items-center
  justify-center
  gap-2

  transition-all
  duration-200

  hover:bg-none
  active:bg-none
  active:shadow-none

  disabled:bg-none
  disabled:shadow-none
  disabled:text-neutral-500
  disabled:bg-neutral-300
  disabled:border-neutral-300
  disabled:cursor-not-allowed
  disabled:hover:bg-none
  disabled:active:bg-none
  disabled:cursor-not-allowed
`);

const COLOR_CN = {
  default: `
    text-[#707070]
    bg-[#FAFAFA]
    bg-none
    border-[#C0C0C0]
    hover:bg-[#E5E5E5]
    hover:text-[#606060]
    active:bg-[#D5D5D5]
    active:text-[#505050]
  `,
  amaranth: `
    text-white
    bg-amaranth-600
    border-amaranth-700
    hover:bg-amaranth-700
    active:bg-amaranth-800
  `,
  amber: `
    text-white
    bg-amber-600
    border-amber-700
    hover:bg-amber-700
    active:bg-amber-800
  `,
  blue: `
    text-white
    bg-blue-600
    border-blue-700
    hover:bg-blue-700
    active:bg-blue-800
  `,
  cyan: `
    text-white
    bg-cyan-600
    border-cyan-700
    hover:bg-cyan-700
    active:bg-cyan-800
  `,
  emerald: `
    text-white
    bg-emerald-600
    border-emerald-700
    hover:bg-emerald-700
    active:bg-emerald-800
  `,
  fuchsia: `
    text-white
    bg-fuchsia-600
    border-fuchsia-700
    hover:bg-fuchsia-700
    active:bg-fuchsia-800
  `,
  gallery: `
    text-white
    bg-gallery-600
    border-gallery-700
    hover:bg-gallery-700
    active:bg-gallery-800
  `,
  gray: `
    text-white
    bg-gray-600
    border-gray-700
    hover:bg-gray-700
    active:bg-gray-800
  `,
  green: `
    text-white
    bg-green-600
    border-green-700
    hover:bg-green-700
    active:bg-green-800
  `,
  indigo: `
    text-white
    bg-indigo-600
    border-indigo-700
    hover:bg-indigo-700
    active:bg-indigo-800
  `,
  lime: `
    text-white
    bg-lime-600
    border-lime-700
    hover:bg-lime-700
    active:bg-lime-800
  `,
  neutral: `
    text-white
    bg-neutral-600
    border-neutral-700
    hover:bg-neutral-700
    active:bg-neutral-800
  `,
  orange: `
    text-white
    bg-orange-600
    border-orange-700
    hover:bg-orange-700
    active:bg-orange-800
  `,
  pink: `
    text-white
    bg-pink-600
    border-pink-700
    hover:bg-pink-700
    active:bg-pink-800
  `,
  purple: `
    text-white
    bg-purple-600
    border-purple-700
    hover:bg-purple-700
    active:bg-purple-800
  `,
  red: `
    text-white
    bg-red-600
    border-red-700
    hover:bg-red-700
    active:bg-red-800
  `,
  rose: `
    text-white
    bg-rose-600
    border-rose-700
    hover:bg-rose-700
    active:bg-rose-800
  `,
  sky: `
    text-white
    bg-sky-600
    border-sky-700
    hover:bg-sky-700
    active:bg-sky-800
  `,
  slate: `
    text-white
    bg-slate-600
    border-slate-700
    hover:bg-slate-700
    active:bg-slate-800
  `,
  steel: `
    text-white
    bg-steel-600
    border-steel-700
    hover:bg-steel-700
    active:bg-steel-800
  `,
  stone: `
    text-white
    bg-stone-600
    border-stone-700
    hover:bg-stone-700
    active:bg-stone-800
  `,
  teal: `
    text-white
    bg-teal-600
    border-teal-700
    hover:bg-teal-700
    active:bg-teal-800
  `,
  violet: `
    text-white
    bg-violet-600
    border-violet-700
    hover:bg-violet-700
    active:bg-violet-800
  `,
  yellow: `
    text-white
    bg-yellow-600
    border-yellow-700
    hover:bg-yellow-700
    active:bg-yellow-800
  `,
  zinc: `
    text-white
    bg-zinc-600
    border-zinc-700
    hover:bg-zinc-700
    active:bg-zinc-800
  `,
};

export default function buttonCn(args) {
  const {
    color = 'default',
  } = args;

  return cn(BASE_CN, COLOR_CN[color], args.className);
}
