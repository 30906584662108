import cn from '@utils/cn';

const CLASS_NAMES = cn(`
  container
  mx-auto
  max-w-(--breakpoint-2xl)
  px-4
  pt-0
  pb-4
  md:pt-navbar-height

  flex
  flex-col
  gap-4
`);

export default function Page(props) {
  const {
    className,
  } = props;

  return (
    <div className={cn(CLASS_NAMES, className)}>
      {props.children}
    </div>
  );
}
