// @index('./menu_item_types/(?!index|_)*.js', f => `import ${f.name} from '@components/navbar/menu/menu_item_types/${f.name}';`)
import NavbarMenuBaseItem from '@components/navbar/menu/menu_item_types/NavbarMenuBaseItem';
import NavbarMenuDividerItem from '@components/navbar/menu/menu_item_types/NavbarMenuDividerItem';
import NavbarMenuHeadingItem from '@components/navbar/menu/menu_item_types/NavbarMenuHeadingItem';
import NavbarMenuLinkItem from '@components/navbar/menu/menu_item_types/NavbarMenuLinkItem';
import NavbarMenuOperationItem from '@components/navbar/menu/menu_item_types/NavbarMenuOperationItem';
// @endindex

const ITEM_TYPE_COMPONENTS = {
  base: NavbarMenuBaseItem,
  divider: NavbarMenuDividerItem,
  heading: NavbarMenuHeadingItem,
  link: NavbarMenuLinkItem,
  operation: NavbarMenuOperationItem,
};

export default function NavbarMenuItem(props) {
  const {
    type,
  } = props;

  const ItemComponent = ITEM_TYPE_COMPONENTS[type];
  if (!ItemComponent) return null;

  return (
    <ItemComponent {...props} />
  );
}
