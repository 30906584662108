import Page from '@components/page/Page';
import LoaderCircle from '@elements/LoaderCircle';

export default function PageLoader() {
  return (
    <Page>
      <div className="flex justify-center mt-32">
        <LoaderCircle
          size="min(20vw, 20vh)"
          width="0.5rem"
          color="#AAAAAA"
        />
      </div>
    </Page>
  );
}
