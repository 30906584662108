import { MenuDivider } from '@szhsin/react-menu';

import cn from '@utils/cn';

const CLASS_NAMES = cn(`
  block
  p-0
  my-2
  h-px
  border-none
  bg-gray-400/40
`);

export default function NavbarMenuDividerItem(_props) {
  return (
    <MenuDivider>
      <hr className={CLASS_NAMES} />
    </MenuDivider>
  );
}
