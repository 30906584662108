import { useCallback } from 'react';

import { redirectTo, Router, getIconProps } from '@utils';
import Icon from '@elements/Icon';
import NavbarBaseSlot from '@components/navbar/slot_types/NavbarBaseSlot';

export default function NavbarLinkSlot(props) {
  const {
    label,
    tooltip,
    path,
    url,
  } = props;

  const {
    text,
  } = label;

  const iconProps = getIconProps(label.icon, {
    fixedWidth: true,
  });

  /**************************************************************************************************/

  const handleClick = useCallback((_event) => {
    const targetUrl = (path && Router.buildUrlFor(path)) || url;

    redirectTo(targetUrl);
  }, [path, url]);

  /**************************************************************************************************/

  return (
    <NavbarBaseSlot
      className="px-3"
      onClick={handleClick}
      tooltip={tooltip}
    >
      {iconProps && (
        <span className="pointer-events-none inline-flex size-6 shrink-0 items-center justify-center">
          <Icon {...iconProps} />
        </span>
      )}

      {text}
    </NavbarBaseSlot>
  );
}
