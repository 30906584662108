import { isObject, getResourceConfig } from '@utils';

/***************************************************************************************************
** FontAwesome
***************************************************************************************************/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-0a2cd91682/icons';

const getFontAwesomeIconValue = (iconName) => {
  if (typeof (iconName) === 'string') {
    if (iconName.includes(':')) {
      const [style, name] = iconName.split(':');
      return byPrefixAndName[style][name];
    } else {
      return byPrefixAndName.fas[iconName];
    }
  } else {
    const [style, name] = iconName;
    return byPrefixAndName[style][name];
  }
};

/***************************************************************************************************
** Icon Component
***************************************************************************************************/

const RESOURCE_ICON_IDENTIFIER = 'resource://';

const hasResourceIconIdentifier = (value) => typeof(value) === 'string' && value.startsWith(RESOURCE_ICON_IDENTIFIER);
const extractResourceName = (value) => value.slice(RESOURCE_ICON_IDENTIFIER.length);
const stringifyConfigObject = (obj) => Object.entries(obj).map(([key, value]) => [key, value].join('-')).join(' ');

const ResourceIcon = (props) => {
  const { resourceName, ...otherProps } = props;

  const resourceConfig = getResourceConfig(resourceName);
  if (!resourceConfig) return null;

  const iconName = resourceConfig.icon?.name;
  const iconProps = {
    ...otherProps,
    ...resourceConfig.icon,
  };

  return (
    <Icon
      iconName={iconName}
      {...iconProps}
    />
  );
};

/**************************************************************************************************/

const defaultSubIconStyles = {
  stroke: 'var(--icon-background-color, #FFFFFF)',
  strokeWidth: 150,
  paintOrder: 'stroke fill',
  transitionDuration: '0.25s',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionProperty: 'stroke',
};

const defaultSubscriptedIconProps = {
  iconScale: 0.85,
  subIconShrink: 6,
  subIconDown: 6,
  subIconRight: 6,
};

const SubscriptedIcon = (props) => {
  const {
    iconName,
    subIconName,
    subIconColor,
    subIconShrink,
    subIconDown,
    subIconRight,
    iconScale,
    wrapperWidth,
    ...otherProps
  } = {
    ...defaultSubscriptedIconProps,
    ...props,
  };

  const subIconProps = {
    transform: {
      shrink: subIconShrink,
      down: subIconDown,
      right: subIconRight,
    },
  };

  const subIconStyles = {
    ...defaultSubIconStyles,
  };

  if (subIconColor) {
    subIconProps.color = subIconColor;
  } else if (otherProps.color) {
    subIconProps.color = otherProps.color;
  } else {
    subIconStyles.color = 'var(--icon-foreground-color)';
  }

  if (otherProps.size) {
    subIconProps.size = otherProps.size;
  } else {
    subIconStyles.fontSize = 'var(--icon-size, 1rem)';
  }

  if (otherProps.subIconStrokeWidth) {
    subIconStyles.strokeWidth = otherProps.subIconStrokeWidth;
  }

  return (
    <span
      className="fa-layers fa-fw"
      style={{
        transform: `scale(${iconScale})`,
        width: wrapperWidth,
      }}
    >
      <Icon
        iconName={iconName}
        {...otherProps}
      />
      <Icon
        iconName={subIconName}
        style={subIconStyles}
        {...subIconProps}
      />
    </span>
  );
};

/**************************************************************************************************/

const defaultSlashIconStyles = {
  stroke: 'var(--icon-background-color, #FFFFFF)',
  strokeWidth: 75,
  paintOrder: 'stroke fill',
  transitionDuration: '0.25s',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionProperty: 'stroke',
  transform: 'scaleX(0.75)',
};

const SlashedIcon = (props) => {
  const {
    iconName,
    ...otherProps
  } = props;

  const slashIconStyles = {
    ...defaultSlashIconStyles,
  };

  if (otherProps.color) {
    slashIconStyles.color = otherProps.color;
  } else {
    slashIconStyles.color = 'var(--icon-foreground-color)';
  }

  if (otherProps.size) {
    slashIconStyles.size = otherProps.size;
  } else {
    slashIconStyles.fontSize = 'var(--icon-size, 1rem)';
  }

  return (
    <span className="fa-layers fa-fw">
      <Icon
        iconName={iconName}
        fixedWidth={true}
        {...otherProps}
      />
      <Icon
        iconName="slash"
        style={slashIconStyles}
        fixedWidth={false}
        transform={{
          shrink: 3,
          left: 2,
        }}
      />
    </span>
  );
};

export default function Icon(props) {
  const {
    iconName,
    wrapperWidth,
    isSlashed,
    ...otherProps
  } = props;

  if (!iconName) return null;

  if (hasResourceIconIdentifier(iconName)) {
    return (
      <ResourceIcon
        resourceName={extractResourceName(iconName)}
        {...otherProps}
      />
    );
  }

  if (isObject(otherProps.transform)) {
    otherProps.transform = stringifyConfigObject(otherProps.transform);
  }

  if (isSlashed) {
    return (
      <SlashedIcon
        iconName={iconName}
        {...otherProps}
      />
    );
  }

  if (otherProps.subIconName) {
    return (
      <SubscriptedIcon
        iconName={iconName}
        wrapperWidth={wrapperWidth}
        {...otherProps}
      />
    );
  }

  if (otherProps.mask) {
    otherProps.mask = getFontAwesomeIconValue(otherProps.mask);
  }

  if (otherProps['data-tooltip-content']) {
    return (
      <span
        className="cursor-help"
        data-tooltip-id={otherProps['data-tooltip-id']}
        data-tooltip-content={otherProps['data-tooltip-content']}
      >
        <FontAwesomeIcon
          icon={getFontAwesomeIconValue(iconName)}
          {...otherProps}
        />
      </span>
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={getFontAwesomeIconValue(iconName)}
        {...otherProps}
      />
    );
  }
}
