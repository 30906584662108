import { useCallback } from 'react';

import { redirectTo, Router } from '@utils';
import { useFlags } from '@hooks';
import NavbarMenuBaseItem from '@components/navbar/menu/menu_item_types/NavbarMenuBaseItem';

const FLAG_DEFINITIONS = [
  {
    key: 'isExternalURL',
    label: 'External Link',
    icon: 'up-right-from-square',
  },
];

export default function NavbarMenuLinkItem(props) {
  const url = (props.path && Router.buildUrlFor(props.path)) || props.url;

  const isExternalURL = props.url && props.url.startsWith('http');

  const flags = useFlags(FLAG_DEFINITIONS, { isExternalURL }, props.flags);

  const handleClick = useCallback((_event) => {
    redirectTo(url);
  }, [url]);

  /**************************************************************************************************/

  return (
    <NavbarMenuBaseItem
      onClick={handleClick}
      flags={flags}
      {...props}
    />
  );
}
