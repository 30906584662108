import cn from '@utils/cn';
import { getIconProps } from '@utils';
import Icon from '@elements/Icon';

// TODO: Refactor for new Icon format
const LinkIcon = (props) => {
  if (!props.iconName) return null;

  const iconProps = getIconProps(props.iconName, {
    className: props.iconClassName,
    size: props.iconSize,
    ...props.iconProps,
  });

  const spanClassNames = cn(['inline-flex items-center shrink-0']);

  return (
    <span className={spanClassNames}>
      <Icon {...iconProps} />
    </span>
  );
};

const defaultProps = {
  placeholder: '--',
  isIconOnly: false,
  isDisplayed: true,
  isLoading: false,
};

export default function Link(props) {
  // const {} = props;

  /**************************************************************************************************/

  const isIconOnly = props.isIconOnly ?? defaultProps.isIconOnly;
  const placeholder = !isIconOnly && (props.placeholder || defaultProps.placeholder);
  const isDisplayed = props.isDisplayed ?? defaultProps.isDisplayed;
  const isLoading = props.isLoading ?? defaultProps.isLoading;

  const target = props.openInNewWindow ? '_blank' : props.target;
  const rel = props.openInNewWindow ? 'noreferrer' : props.rel;

  if (!isDisplayed) return placeholder;

  const classNames = cn(['flex items-center gap-1.5'], props.className, {
    'is-loading': isLoading,
  });

  /**************************************************************************************************/

  return (
    <a
      href={props.url}
      className={classNames}
      onClick={props.onClick}
      style={props.style}
      target={target}
      disabled={props.isDisabled}
      ref={props.ref}
      format={props.format}
      rel={rel}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={props.tooltip || props['data-tooltip-content']}
    >
      <LinkIcon
        iconName={props.iconName}
        iconSize={props.iconSize}
        iconClassName={props.iconClassName}
        iconProps={props.iconProps}
        isIconOnly={props.isIconOnly}
      />
      {!isIconOnly && (
        <span className={props.labelClassName}>
          {props.label || props.children || placeholder}
        </span>
      )}
    </a>
  );
}
