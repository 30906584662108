import { useMemo } from 'react';

import cn from '@utils/cn';

const classNames = cn(`
  animate-spin
  border-[2px]
  border-solid
  border-black/10
  rounded-full
  border-r:transparent
  border-t:transparent
  content-[""]
  block
  relative
  h-[1em]
  w-[1em]
`);

const defaultProps = {
  color: '#999999',
  width: '0.25rem',
  size: '1rem',
  margin: '0.5rem',
};

export default function LoaderCircle(props) {
  const {
    color,
    width,
    size,
    margin,
  } = { ...defaultProps, ...props };

  const styles = useMemo(() => ({
    borderTopColor: `${color}44`,
    borderRightColor: `${color}44`,
    borderBottomColor: color,
    borderLeftColor: color,
    borderWidth: width,
    height: size,
    width: size,
    margin: margin,
  }), [color, width, size, margin]);

  return (
    <div
      className={classNames}
      style={styles}
    />
  );
}
