import { MenuHeader } from '@szhsin/react-menu';

import cn from '@utils/cn';
import NavbarMenuDividerItem from '@components/navbar/menu/menu_item_types/NavbarMenuDividerItem';

const CLASS_NAMES = cn(`
  px-4
  py-2
  text-[11px]
  uppercase
  leading-normal
  tracking-[1px]
  bg-white
  text-[#4A4A4A]
`);

export default function NavbarMenuHeadingItem(props) {
  const {
    label,
  } = props;

  const hasDivider = props.hasDivider ?? false;

  const text = label.text;

  /**************************************************************************************************/

  return (
    <>
      {hasDivider && (
        <NavbarMenuDividerItem />
      )}

      <MenuHeader className={CLASS_NAMES}>
        {text}
      </MenuHeader>
    </>
  );
}
