import Page from '@components/page/Page';
import Icon from '@elements/Icon';

export default function ErrorFallbackPage(props) {
  const {
    error,
    _resetErrorBoundary,
  } = props;

  return (
    <Page>
      <div className="rounded-lg border border-zinc-300 bg-white p-5 text-gallery-800">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <br />
            <div className="text-center text-5xl">
              <Icon iconName="triangle-exclamation" />
            </div>
            <div className="text-center text-[2rem]">
              Something went wrong!
            </div>
            <hr />
            {error?.message && (
              <pre>
                {error.message}
              </pre>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}
