import { MenuItem } from '@szhsin/react-menu';

import cn from '@utils/cn';
// import { useSessionContext } from '@contexts/SessionContext';
import { getIconProps } from '@utils';
import Icon from '@elements/Icon';
import Flags from '@elements/Flags';

const BASE_CLASS_NAMES = cn(`
  group
  pl-4
  pr-8
  py-[0.5rem]

  flex
  items-center
  justify-start
  whitespace-nowrap

  font-medium
  tracking-wide
  cursor-pointer
  text-base
  outline-hidden

  text-[#555555]
  bg-[var(--action-menu-item-background-color)]
  [--icon-background-color:var(--action-menu-item-background-color)]

  hover:text-[#333333]
  hover:bg-[var(--action-menu-item-hover-background-color)]
  hover:[--icon-background-color:var(--action-menu-item-hover-background-color)]

  focus:text-[#333333]
  focus:bg-[var(--action-menu-item-hover-background-color)]
  focus:[--icon-background-color:var(--action-menu-item-hover-background-color)]

  active:text-[#333333]
  active:bg-[var(--action-menu-item-active-background-color)]
  active:[--icon-background-color:var(--action-menu-item-active-background-color)]

  data-[selected=true]:text-white
  data-[selected=true]:bg-[var(--navbar-menu-active-background-color)]
  data-[selected=true]:[--icon-background-color:var(--navbar-menu-active-background-color)]
`);

const DISABLED_CLASS_NAMES = cn(`
  cursor-not-allowed

  bg-[#F5F5F5]
  text-[#999999]
  [--icon-background-color:#F5F5F5]

  hover:bg-[#F5F5F5]
  hover:text-[#999999]
  hover:[--icon-background-color:#F5F5F5]

  focus:bg-[#F5F5F5]
  focus:text-[#999999]
  focus:[--icon-background-color:#F5F5F5]

  active:bg-[#F5F5F5]
  active:text-[#999999]
  active:[--icon-background-color:#F5F5F5]
`);

export default function NavbarMenuBaseItem(props) {
  const {
    className,
    label = {},
    onClick,

    isDisabled,
    isAdminOnly,
    flags = [],
  } = props;

  const {
    text,
    icon,
  } = label;

  const iconProps = getIconProps(icon, {
    fixedWidth: true,
  });

  // const { isCurrentPage } = useSessionContext();
  // const isActive = isCurrentPage(pageKey);

  const classNames = cn(BASE_CLASS_NAMES, className, {
    [DISABLED_CLASS_NAMES]: isDisabled,
  });

  /**************************************************************************************************/

  return (
    <MenuItem
      className={classNames}
      onClick={onClick}
      disabled={isDisabled}
    >
      {iconProps && (
        <span className="duration-250 my-0 ml-0 mr-2 align-middle transition ease-in-out">
          <Icon {...iconProps} />
        </span>
      )}

      <span>
        {text}
        {isAdminOnly ? '*' : ''}
      </span>

      <Flags
        flags={flags}
        flagClassNames="opacity-75 ml-2"
        displayTooltip={false}
      />
    </MenuItem>
  );
};
