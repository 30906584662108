import { toast } from 'react-toastify';

import './toastify.css';

/**************************************************************************************************/

const toastify = {};

/***************************************************************************************************
** Toastify Function
***************************************************************************************************/

const TOASTIFY_TYPES = [
  'default',
  'success',
  'error',
  'info',
  'warning',
];

const defaultArgs = {
  type: 'default',
  value: '<No Message>',
};

const toastifyFunction = (args) => {
  const type = args.type || defaultArgs.type;
  const value = args.value ?? defaultArgs.value;
  const typeTitle = type.toUpperCase();

  if (!value) return;

  const Content = (
    <div>
      <b>{typeTitle}</b>
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );

  toast(Content, {
    type: type,
    toastId: args.toastId,
    autoClose: args.autoClose ?? 5000,
    delay: args.delay ?? 0,
  });
};

TOASTIFY_TYPES.forEach((type) => {
  toastify[type] = (value, args) => toastifyFunction({ type: type, value: value, ...args });
});

toastify.notify = (args) => toastifyFunction(args);

toastify.update = (args) => {
  const type = args.type || defaultArgs.type;
  const value = args.value ?? defaultArgs.value;
  const typeTitle = type.toUpperCase();

  if (!value) return;
  if (!args.toastId) return;

  const Content = (
    <div>
      <b>{typeTitle}</b>
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );

  toast.update(args.toastId, {
    render: () => Content,
    type: type,
    autoClose: args.autoClose ?? 5000,
    delay: args.delay ?? 0,
  });
};

/**************************************************************************************************/

const SESSION_STORAGE_TOAST_KEY = 'toasts';
const QUEUED_TOAST_DELAY = 1000;

const getToastsFromSessionStorage = () => JSON.parse(sessionStorage.getItem(SESSION_STORAGE_TOAST_KEY)) || [];

toastify.onReload = (args) => {
  const toasts = getToastsFromSessionStorage();
  toasts.push(args);
  sessionStorage.setItem(SESSION_STORAGE_TOAST_KEY, JSON.stringify(toasts));
};

toastify.processToastQueue = () => {
  const toasts = getToastsFromSessionStorage();

  toasts.forEach((args, index) => {
    setTimeout(() => {
      toastify.notify(args);
    }, QUEUED_TOAST_DELAY * index);
  });

  sessionStorage.removeItem(SESSION_STORAGE_TOAST_KEY);
};

export default toastify;
