import cn from '@utils/cn';

const CLASS_NAMES = cn(`
  flex
  px-4
  h-navbar-height
  items-center
  justify-start
  gap-2

  cursor-pointer
  select-none

  text-base
  font-medium
  tracking-wide

  transition-all
  duration-200

  ring-0
  outline-hidden
  focus:ring-0
  focus:outline-hidden
  active:ring-0
  active:outline-hidden

  text-white
  hover:bg-[var(--navbar-menu-hover-background-color)]
  focus:bg-[var(--navbar-menu-focus-background-color)]
  active:bg-[var(--navbar-menu-focus-background-color)]
  hover:[--icon-background-color:var(--navbar-menu-hover-background-color)]
  focus:[--icon-background-color:var(--navbar-menu-focus-background-color)]
  active:[--icon-background-color:var(--navbar-menu-focus-background-color)]
  data-[selected=true]:text-white
  data-[selected=true]:bg-[var(--navbar-menu-active-background-color)]
  data-[selected=true]:[--icon-background-color:var(--navbar-menu-active-background-color)]
`);

export default function NavbarBaseSlot(props) {
  const {
    className,
    onClick,
    tooltip,
  } = props;

  const classNames = cn(CLASS_NAMES, className);

  return (
    <div
      className={classNames}
      onClick={onClick}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
    >
      {props.children}
    </div>
  );
}
