import { MenuButton } from '@szhsin/react-menu';

import cn from '@utils/cn';
import { getIconProps } from '@utils';
import Icon from '@elements/Icon';
import Avatar from '@elements/Avatar';

const CLASS_NAMES = cn(`
  flex
  px-4
  h-navbar-height
  items-center
  justify-start
  gap-2

  cursor-pointer
  select-none

  text-base
  font-medium
  tracking-wide

  transition-all
  duration-200

  ring-0
  outline-hidden
  focus:ring-0
  focus:outline-hidden
  active:ring-0
  active:outline-hidden

  text-white
  hover:bg-[var(--navbar-menu-hover-background-color)]
  focus:bg-[var(--navbar-menu-focus-background-color)]
  active:bg-[var(--navbar-menu-focus-background-color)]
  hover:[--icon-background-color:var(--navbar-menu-hover-background-color)]
  focus:[--icon-background-color:var(--navbar-menu-focus-background-color)]
  active:[--icon-background-color:var(--navbar-menu-focus-background-color)]
  data-[selected=true]:text-white
  data-[selected=true]:bg-[var(--navbar-menu-active-background-color)]
  data-[selected=true]:[--icon-background-color:var(--navbar-menu-active-background-color)]
`);

export default function NavbarMenuButton(props) {
  const {
    label,
    anchorRef,
    anchorProps,
    isOpen,
  } = props;

  const {
    text,
    imageUrl,
  } = label;

  const iconProps = getIconProps(label.icon);

  /**************************************************************************************************/

  return (
    <MenuButton
      className={CLASS_NAMES}
      ref={anchorRef}
      data-selected={isOpen}
      {...anchorProps}
    >
      {iconProps && (
        <Icon {...iconProps} />
      )}

      {imageUrl && (
        <Avatar
          className="visible-when-not-mobile"
          size="1.5rem"
          url={imageUrl}
        />
      )}

      {text}

      <Icon
        iconName="chevron-down"
        color="white"
        size="xs"
      />
    </MenuButton>
  );
}
