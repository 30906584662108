import buttonCn from '@utils/buttonCn';
import Link from '@elements/Link';

export default function LinkButton(props) {
  const {
    className,
    color,
    ...rest
  } = props;

  return (
    <Link
      className={buttonCn({
        color: color,
        className: className,
      })}
      {...rest}
    />
  );
}
